$page-padding: 1.5rem;

.nh-page {
  padding: $page-padding;
  &__header {
    margin-bottom: 1rem;
    width: 100%;
    display: block;
    &--limited {
      width: 55rem;
      max-width: 100%;
      @media screen and (max-width: 1280px) {
        width: 100%;
      }
      // TODO: fix aspose bug
      // @media screen and (min-width: 1500px) {
      //   width: calc(100% - 27.5rem);
      // }
    }
    &--centered {
      margin-left: auto;
      margin-right: auto;
    }
    &--top {
      margin-bottom: 0.75rem;
    }
    &--title,
    &__title,
    &__title-container {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      nxh-back-button {
        margin-right: 1rem;
      }
      .title-container {
        display: flex;
        align-items: center;
      }
      &--left {
        display: flex;
        align-items: center;
      }
      &--centered {
        justify-content: center;
      }
      &__controls {
        display: flex;
        align-items: center;
        > * {
          margin-right: 0.5rem;
          &:last-child {
            margin: 0;
          }
        }
        nxh-icon-button {
          .icon-button {
            width: auto;
            height: auto;
            padding: 0.375rem 0.75rem;
          }
        }
      }
    }
    &--bottom {
      margin-top: 0.75rem;
    }
    &--no-margin {
      margin: 0;
    }
  }
  &__content {
    width: 100%;
    display: block;
    &--limited {
      width: 55rem;
      max-width: 100%;
      @media screen and (max-width: 1280px) {
        width: 100%;
      }
      // TODO: fix aspose bug
      // @media screen and (min-width: 1500px) {
      //   min-width: calc(100% - 27.5rem);
      // }
    }
    &--centered {
      margin-left: auto;
      margin-right: auto;
    }
    &--body,
    &__body {
      margin: 0.75rem 0 0;

      > * {
        margin: 0 0 1rem;
        &:last-child {
          margin: 0;
        }
      }
    }
    &--bottom {
      margin-top: 2.5rem;
    }
  }
  &__legal {
    .nh-page__content {
      &--limited {
        width: 71rem;
        max-width: 100%;
      }
      &--split {
        display: flex;
      }
      &--body {
        height: calc(100vh - 10rem);
        overflow-y: auto;
        padding-right: 1rem;
        h1 {
          font-size: 2rem;
          font-weight: bold;
          margin: 1rem 0 2rem;
        }
        h2 {
          font-size: 1.125rem;
          font-weight: 500;
          margin: 2rem 0 0.25rem;
        }
        h3 {
          font-size: 1rem;
          font-weight: 500;
          margin: 1rem 0 0.5rem;
        }
        h4 {
          font-size: 0.875rem;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
}

.patient-page__content {
  .nh-page,
  .nh-page-wrapper {
    padding: 0 0 1rem;
  }
}

.nh-page-wrapper {
  padding: $page-padding;

  .nh-page {
    padding: 0;
  }
}

/**
  Gives a scroll container a scrollbar gutter if this component contains an nxh-page that
  - is *not* underneath an nxh-page-tabs (nxh-page-tabs is also a scroll container with a scrollbar gutter).
  - does not have custom scrolling (in that case this scroll container will never have a scrollbar, because the
  nxh-page will be as high as the scroll container)
 */
.nxh-scrollbar-gutter-stable {
  &:has(nxh-page:not(.nxh-page--scroll-mode-custom)):not(:has(nxh-page-tabs)) {
    scrollbar-gutter: stable both-edges;
  }
}
